<template>
  <div class="data-table">
    <el-table :data="displayData">
      <EmptyBlock slot="empty" />
      <el-table-column label="圖片" prop="image" align="center">
        <template slot-scope="scope">
          <img style="margin: auto;" width="90" :src="computedData(scope.row, 'image')" alt="">
        </template>
      </el-table-column>
      <el-table-column label="名稱" prop="name" align="center" />
      <el-table-column label="優惠碼數量" prop="sentLimit" align="center" />
      <el-table-column label="使用起迄時間" prop="displayTimeRange" align="center" />
      <el-table-column label="折抵比例/金額" prop="discount" align="center" />
      <el-table-column label="操作" fixed="right" width="110" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            :editBtn="computedData(scope.row, 'isDisabled') ? '查看' : '編輯'"
            :deleteDisabled="computedData(scope.row, 'isDisabled')"
            deleteBtn="停用"
            @edit="onRowEdit(scope.row)"
            @delete="onRowDelete(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>

    <DeleteDialog
      v-if="modal.delete"
      title="提醒"
      content="停用後將無法復原，確定要停用？"
      width="40%"
      btnString="停用"
      @close="modal.delete = false"
      @delete="deleteRow"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref, reactive } from 'vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { DisableCouponOpentix } from '@/api/opentix'
import { useTable } from '@/use/table'
import { get, map } from 'lodash'
import { useRouter } from 'vue-router/composables'
import { imgSrc, computedData } from '@/utils/helper'

export default defineComponent({
  name: 'DataTable',
  components: { EmptyBlock },
  props: {
    tableData: { type: Array, defalt: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const { shopId, dateFormat } = useTable()
    const router = useRouter()
    const selectRow = ref(null)
    const modal = reactive({
      delete: false,
    })

    const displayData = computed(() => {
      const data = map(props.tableData, (item) => {
        const startTime = dateFormat(get(item, 'extraSetting.openTixConfig.startTime'), 'YYYY/MM/DD HH:mm:ss')
        const endTime = dateFormat(get(item, 'extraSetting.openTixConfig.endTime'), 'YYYY/MM/DD HH:mm:ss')

        const discountPercentege = get(item, 'extraSetting.openTixConfig.currentDiscount') ? (get(item, 'extraSetting.openTixConfig.currentDiscount') % 10 === 0 ? `${get(item, 'extraSetting.openTixConfig.currentDiscount') / 10} 折` : `${100 - get(item, 'extraSetting.openTixConfig.currentDiscount')} 折`) : null
        const discountPrice = get(item, 'extraSetting.openTixConfig.currentPrice') ? `${get(item, 'extraSetting.openTixConfig.currentPrice')} 元` : null

        return {
          id: item.id,
          image: imgSrc(300, get(item, 'Image')),
          name: item.name,
          sentLimit: get(item, 'firstSentLimit') === null ? '無上限' : (get(item, 'sentLimit') || get(item, 'firstSentLimit')) - get(item, 'sentCount'),
          isDisabled: get(item, 'extraStatus.opentixIsDisabled'),
          displayTimeRange: `${startTime} ~ ${endTime}`,
          discount: discountPercentege || discountPrice,
        }
      })
      // handle data here
      return data
    })

    const onRowEdit = (row) => {
      router.push({
        name: 'OpentixCouponEdit',
        params: {
          id: row.id,
          shopId: shopId.value,
        },
      })
    }

    const onRowDelete = (row) => {
      selectRow.value = row
      modal.delete = true
    }

    const deleteRow = async () => {
      const [, err] = await DisableCouponOpentix({
        shopId: shopId.value,
        id: get(selectRow.value, 'id'),
      })
      if (err) return window.$message.error(err)
      window.$message.success('停用成功！')
      modal.delete = false
      emit('refresh')
    }

    return {
      modal,
      displayData,
      onRowEdit,
      onRowDelete,
      deleteRow,
      imgSrc,
      computedData,
    }
  },
})
</script>
