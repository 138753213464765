<template>
  <div class="opentix-coupon-list">
    <PageTitle title="OPENTIX 優惠券設定" btn="新增" @btnClick="onCreate" />
    <OpentixCouponTable v-loading="loading.table" :tableData="tableData" @refresh="refresh(false)" />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, onActivated, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import { useTable } from '@/use/table'
import { GetCouponOpentix, GetCouponOpentixCount } from '@/api/opentix'
import OpentixCouponTable from './components/OpentixCouponTable.vue'
import PageTitle from '@/components/Title/PageTitle.vue'

export default defineComponent({
  name: 'OpentixCouponList',
  components: {
    PageTitle,
    OpentixCouponTable,
  },
  setup (props) {
    const router = useRouter()
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      shopId,
      fetchData,
      fetchDataCount,
    } = useTable()

    const onCreate = () => {
      router.push({ name: 'OpentixCouponEdit' })
    }

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
      }
      await Promise.allSettled([
        fetchData(GetCouponOpentix, payload),
        fetchDataCount(GetCouponOpentixCount, payload),
      ])
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    onMounted(() => {
      refresh()
    })
    onActivated(() => {
      refresh()
    })
    return {
      loading,
      tableData,
      tableOptions,
      tableDataCount,
      onCreate,
      refresh,
    }
  },
})
</script>
